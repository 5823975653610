import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { Content } from "../config/content";
import env from "../config/env";

const API_ENDPOINT = env.API_ENDPOINT;

const client = new ApolloClient({
  uri: API_ENDPOINT,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default async function fetchApp() {
  try {
    const { data } = await client.query({
      query: gql`
        query {
          entries {
            id
            page
            components {
              homePage {
                homeBackground {
                  src: url
                  alt: name
                }
                homeHeader
                homeText
              }
              clinicalAreasPage {
                clinicalTitle
                clinicalBackground {
                  src: url
                  alt: name
                }
              }
              clinicalAreas {
                id
                title
                cta
                link: Link {
                  study {
                    slug
                    clinicalTitle
                    description
                    studiesBackground {
                      src: url
                      alt: name
                    }
                    glossaries {
                      id
                      title
                      def
                    }
                    pipelines {
                      id
                      product
                      therapyArea
                      phase
                      phaseLabel
                      pipelineLogo {
                        src: url
                        alt: name
                      }
                      components: Components {
                        type: __typename

                        ... on ComponentIntroductionIntroduction {
                          tab1: tabTitle
                          logo {
                            src: url
                            alt: name
                          }
                          title
                          titleColor{
                            value
                          }
                          body
                          bodyColor{
                            value
                          }
                          trialLabel
                          trialLabelColor{
                            value
                          }
                          labelValue
                          labelValueColor{
                            value
                          }
                        }
                        ... on ComponentMediaMedia {
                          tab2: tabTitle
                          image {
                            src: url
                            alt: name
                          }
                        }
                        ... on ComponentTwoColumnsTwoColumns {
                          tab3: tabTitle
                          leftItems: LeftItems {
                            title
                            titleColor{
                              value
                            }
                            items: Items {
                              id
                              text
                              logo {
                                src: url
                                alt: name
                              }
                            }
                          }
                          rightItems: RightItems {
                            title
                            titleColor{
                              value
                            }
                            items: Items {
                              id
                              text
                              logo {
                                src: url
                                alt: name
                              }
                            }
                          }
                        }
                        ... on ComponentRichTextRichText {
                          tab4: tabTitle
                          text
                          listHeaderColor{
                            value
                          }
                        }
                        ... on ComponentThreeColumnsThreeColumns {
                          tab5: tabTitle
                          subTitle
                          subTitleColor{
                            value
                          }
                          columnItems {
                            id
                            title
                            titleColor{
                              value
                            }
                            items
                          }
                        }
                        ... on ComponentCarouselsCarousels {
                          tab6: tabTitle
                          carouselItems {
                            id
                            title
                            titleColor{
                              value
                            }
                            image {
                              src: url
                              alt: name
                            }
                            description
                            descriptionColor{
                              value
                            }
                          }
                        }
                        ... on ComponentMediaTextMediaText {
                          title
                          description
                          media: Media {
                            mime
                            src: url
                          }
                        }
                        ... on ComponentReferencesReferences {
                          tab8: tabTitle
                          abbreviationLabel
                          abbreviationCopy
                          referenceLabel
                          referenceCopy
                        }
                      }
                    }
                    footerText: FooterText
                    footerProducts: pipelines_footers {
                      id
                      product
                      therapyArea
                      phase
                      phaseLabel
                      pipelineLogo {
                        url
                        alt: name
                      }
                      buttonVariant
                      components: Components {
                        type: __typename

                        ... on ComponentIntroductionIntroduction {
                          tab1: tabTitle
                          logo {
                            src: url
                            alt: name
                          }
                          title
                          titleColor{
                            value
                          }
                          body
                          bodyColor{
                            value
                          }
                          trialLabel
                          trialLabelColor{
                            value
                          }
                          labelValue
                          labelValueColor{
                            value
                          }
                        }
                        ... on ComponentMediaMedia {
                          tab2: tabTitle
                          image {
                            src: url
                            alt: name
                          }
                        }
                        ... on ComponentTwoColumnsTwoColumns {
                          tab3: tabTitle
                          leftItems: LeftItems {
                            title
                            titleColor{
                              value
                            }
                            items: Items {
                              id
                              text
                              logo {
                                src: url
                                alt: name
                              }
                            }
                          }
                          rightItems: RightItems {
                            title
                            titleColor{
                              value
                            }
                            items: Items {
                              id
                              text
                              logo {
                                src: url
                                alt: name
                              }
                            }
                          }
                        }
                        ... on ComponentRichTextRichText {
                          tab4: tabTitle
                          text
                          listHeaderColor{
                            value
                          }
                        }
                        ... on ComponentThreeColumnsThreeColumns {
                          tab5: tabTitle
                          subTitle
                          subTitleColor{
                            value
                          }
                          columnItems {
                            id
                            title
                            titleColor{
                              value
                            }
                            items
                          }
                        }
                        ... on ComponentCarouselsCarousels {
                          carouselItems {
                            id
                            title
                            titleColor{
                              value
                            }
                            image {
                              src: url
                              alt: name
                            }
                            description
                            descriptionColor{
                              value
                            }
                          }
                        }
                        ... on ComponentMediaTextMediaText {
                          title
                          description
                          media: Media {
                            mime
                            src: url
                          }
                        }
                        ... on ComponentReferencesReferences {
                          abbreviationLabel
                          abbreviationCopy
                          referenceLabel
                          referenceCopy
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          footer {
            footerCopyright
            footerJobCode
            footerLogo {
              src: url
              alt: name
            }
          }
        }
      `,
    });

    //todo: Error handling

    let formattedData: Partial<Content> = {
      footer: data.footer,
      studiesTable: [
        "STUDY",
        "PRODUCT",
        "THERAPY AREA",
        "DISCOVERY",
        "PRECLINICAL",
        "PHASE1",
        "PHASE2",
        "PHASE3",
        "",
      ],
    };

    data?.entries.forEach((entry: any) => {
      if (entry.page === "home") {
        formattedData.home = {
          homeBackground: entry?.components?.homePage?.homeBackground,
          homeHeader: entry?.components?.homePage?.homeHeader,
          homeText: entry?.components?.homePage?.homeText,
          homeButtonText: "Start",
          homeDialogBox: "Please enter your authentication code",
          homeDialogBack: "Back",
          homeDialogSubmit: "Submit",
          homeIcon: {
            src: "/assets/home-icon.svg",
            alt: "Go home",
          },
          backIcon: { src: "/assets/arrow-icon.svg", alt: "Go Back" },
          glossaryIconText: "abc",
          closeIcon: { src: "/assets/close-icon.png", alt: "Close" },
        };
      } else {
        formattedData.clinicalBackground =
          entry?.components?.clinicalAreasPage?.clinicalBackground?.src;
        formattedData.clinicalTitle =
          entry?.components?.clinicalAreasPage?.clinicalTitle;
        formattedData.clinicalAreas = entry?.components?.clinicalAreas;
      }
    });

    return formattedData;
  } catch (err) {
    console.error(err);
  }
}
