import create from "zustand";
import appContent, { Content } from "../../config/content";

export type AppState = {
  content: Content;
  setContent: Function;
};

export const useApp = create<any>((set) => {
  return {
    content: appContent,
    setContent: (content: Content) => set({ content }),
  };
});
