import { Area, StudiesTable, Footer, Home } from "./types";

export type Content = {
  footer: Footer;
  home: Home;
  clinicalBackground: string;
  clinicalTitle: string;
  clinicalAreas: Area[];
  studiesTable: StudiesTable;
};

// eslint-disable-next-line import/no-anonymous-default-export
const content: Content = {
  footer: {
    footerLogo: {
      src: "/assets/footer-logo.png",
      alt: "GLPG",
    },
    footerCopyright: "Copyright 2020 Galapagos & Gilead",
    footerJobCode: "[Job code] | January 2021",
  },
  home: {
    homeBackground: {
      src: "/assets/image-splash-screen.png",
      alt: "Home",
    },
    homeHeader: "The Galapagos and Gilead joint pipeline",
    homeText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vestibulum convallis, at aliquam ipsum tempus eget.",
    homeButtonText: "Start",
    homeDialogBox: "Please enter your authentication code",
    homeDialogBack: "Back",
    homeDialogSubmit: "Submit",
    homeIcon: {
      src: "/assets/home-icon.svg",
      alt: "Go home",
    },
    backIcon: { src: "/assets/arrow-icon.svg", alt: "Go Back" },
    glossaryIconText: "abc",
    closeIcon: { src: "/assets/close-icon.png", alt: "Close" },
  },
  clinicalBackground: "/assets/clinical_background.png",
  clinicalTitle: "The clinical areas we are conducting research in",
  clinicalAreas: [
    {
      id: 0,
      title: "IPF AND PULMONARY FIBROSIS",
      cta: "See our pipeline",
      link: {
        study: {
          slug: "ipf-pulmonary",
          description:
            "Our combined pipeline will accelerate innovation in fibrotic diseases",
          studiesBackground: "/assets/clinical_background.png",
          glossaries: [
            { id: "1", title: "LOREM", def: "nullam eu enim sed odio" },
            { id: "2", title: "SED", def: "nullam eu enim sed odio" },
            { id: "3", title: "LOREM", def: "nullam eu enim sed odio" },
            { id: "4", title: "NEC", def: "nullam eu enim sed odio" },
            { id: "5", title: "VELIT", def: "nullam eu enim sed odio" },
            { id: "6", title: "AENEAN", def: "nullam eu enim sed odio" },
          ],
          pipelines: [
            {
              id: "1",
              product: "Ziritaxestat",
              therapyArea: "IPF",
              phase: "3",
              phaseLabel: "Recruiting",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "ComponentIntroductionIntroduction",
                  tab1: "now recruiting",
                  logo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
                  title:
                    "<h3>ISABELA</h3><h3>Phase 3 study in patients with IPF</h3>",
                  body:
                    "<p>ISABELA is the largest planned Phase 3 clinical program in IPF to date, consisting of two identically designed studies with a total of 1500 patients worldwide.</p><p>Ziritaxestat(GLPG1690) is being assessed as a monotherapy and as an add-on to current standard of care, defined as pirfenidone or nintedanib, or neither pirfenidone nor nintedanib.</p>",
                  trialLabel: "Clinical Trial Number",
                  labelValue: "NCT0371162; NCTO3733444",
                },
                {
                  type: "media",
                  tab2: "study design",
                  image: {
                    alt: "Isabela",
                    src: "/assets/study-placeholder.png",
                  },
                },
                {
                  type: "twocolumn",
                  tab3: "patient population",
                  leftItems: [{
                    title: "Key inclusion criteria",
                    items: [
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                    ],
                  }],
                  rightItems: [{
                    title: "Key exclusion criteria",
                    items: [
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                      {
                        logo: {
                          alt: "Isabela",
                          src: "/assets/isabela-logo.png",
                        },
                        text:
                          "<p>Confirmation of IPF by central review based on HRCT + lung biopsy, based on the Fleischner white paper</p>",
                      },
                    ],
                  }],
                },
                {
                  type: "richtext",
                  tab4: "endpoints",
                  text:
                    "<p>Primary endpoint:</p><li>Rate of decline of FVC in mL, from baseline through week 52</li></br></br><p>Key secondary endpoints:</p><li>Disease progression, defined as the composite endpoint of first occurrence of >10% absolute decline in %FVC or all-cause mortality at 52 weeks</li>",
                },
                {
                  type: "threecolumns",
                  tab5: "assessments",
                  subTitle: "Range of assessments during ISABELA",
                  columnItems: [
                    {
                      title: "EFFICACY",
                      items:
                        "<li>Spirometry</li><li>FVC and %FVC</li><li>Spirometry</li><li>FVC and %FVC</li><li>Spirometry</li><li>FVC and %FVC</li>",
                    },
                    {
                      title: "SAFETY",
                      items:
                        "<li>Spirometry</li><li>FVC and %FVC</li><li>Spirometry</li><li>FVC and %FVC</li>",
                    },
                    {
                      title: "PROs",
                      items:
                        "<li>Spirometry</li><li>FVC and %FVC</li><li>Spirometry</li><li>FVC and %FVC</li>",
                    },
                  ],
                },
                {
                  type: "media",
                  tab6: "country participation",
                  image: { alt: "Map", src: "/assets/placeholder-map.png" },
                },
              ],
            },
            {
              id: "2",
              product: "GLPG1205",
              therapyArea: "IPF",
              phase: "2",
              phaseLabel: "Completed",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },

            {
              id: "3",
              product: "Ziritaxestat",
              therapyArea: "SSc",
              phase: "2",
              phaseLabel: "Completed",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
            {
              id: "4",
              product: "Lorem ipsum",
              therapyArea: "IPF",
              phase: "1",
              phaseLabel: "Planned",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "intro",
                  tab1: "now recruiting",
                  logo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
                  title:
                    "<h3>ISABELA</h3><h3>Phase 3 study in patients with IPF</h3>",
                  body:
                    "<p>ISABELA is the largest planned Phase 3 clinical program in IPF to date, consisting of two identically designed studies with a total of 1500 patients worldwide.</p><p>Ziritaxestat(GLPG1690) is being assessed as a monotherapy and as an add-on to current standard of care, defined as pirfenidone or nintedanib, or neither pirfenidone nor nintedanib.</p>",
                  trialLabel: "Clinical Trial Number",
                  labelValue: "<p>NCT0371162; NCTO3733444</p>",
                },
                {
                  type: "media",
                  tab2: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
            {
              id: "5",
              product: "Ziritaxestat",
              therapyArea: "IPF",
              phase: "a",
              phaseLabel: "Recruiting",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
            {
              id: "6",
              product: "Ziritaxestat",
              therapyArea: "IPF",
              phase: "0",
              phaseLabel: "Recruiting",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
          ],
          footerText: "Find out more about our products",
          footerProducts: [
            {
              buttonVariant: "secondary",
              id: "7",
              product: "Ziritaxestat",
              therapyArea: "IPF",
              phase: "0",
              phaseLabel: "Recruiting",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
            {
              buttonVariant: "tertiary",
              id: "8",
              product: "Ziritaxestat",
              therapyArea: "IPF",
              phase: "0",
              phaseLabel: "Recruiting",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
            {
              buttonVariant: "tertiary",
              id: "9",
              product: "Ziritaxestat",
              therapyArea: "IPF",
              phase: "0",
              phaseLabel: "Recruiting",
              pipelineLogo: { alt: "Isabele", src: "/assets/isabela-logo.png" },
              components: [
                {
                  type: "media",
                  tab1: "study design",
                  image: { alt: "Isabela", src: "" },
                },
              ],
            },
          ],
        },
      },
    },
    {
      id: 1,
      title: "INFLAMMATORY DISEASES",
      cta: "See our pipeline",
      link: undefined,
    },
    {
      id: 2,
      title: "OSTEOARTHRITIS",
      cta: "See our pipeline",
      link: undefined,
    },
  ],
  studiesTable: [
    "STUDY",
    "PRODUCT",
    "THERAPY AREA",
    "DISCOVERY",
    "PRECLINICAL",
    "PHASE1",
    "PHASE2",
    "PHASE3",
    "",
  ],
};

export default content;
