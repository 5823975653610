// eslint-disable-next-line import/no-anonymous-default-export
export default {
  rootSize: 20,
  footerHeight: "90",
  menuHeight: "130",
  tabWidth: "1050px",
  fonts: {
    primary: "Helvetica, sans-serif",
  },
  colors: {
    primary_green: "#00463E",
    primary_red: "#C50F3C",
    primary_orange: "#F58800",
    primary_gray: "#54565B",
    secondary_darkblue: "#001E60",
    secondary_purple: "#702F8A",
    secondary_lemon: "#43B02A",
    secondary_yellow: "#F2CD00",
    secondary_lightblue: "#71B2C9",
    overlay_color: "#e2e4e6",
    light_gray: "#818793",
    text_dark: "#28292C",
    white: "#ffffff",
    black: "#000000",
  },
};