import { createGlobalStyle } from "styled-components";
import vars from "../../../config/vars";

export const StyleSheet = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  html, body, #root {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  html {
    font-family: ${vars.fonts.primary};
    font-size: ${vars.rootSize}px;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-size: ${50 / vars.rootSize}em;
  }

  h2{
    font-size: ${40 / vars.rootSize}em;
    font-weight: 500;
  }

  h3{
    font-size: ${35 / vars.rootSize}em;
    font-weight: 500;
  }

  h4{
    font-size: ${30 / vars.rootSize}em;
  }

  h5{
    font-size: ${25 / vars.rootSize}em;
  }

  h6{
    font-size: ${18 / vars.rootSize}em;
  }

  p {
    font-size: ${20 / vars.rootSize}em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    border: none;
  }

  img {
    max-width: 100%;
  }
`;
