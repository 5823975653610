import appContent from "../config/content";
import env from "../config/env";
import fetchApp from "./fetch-app";

export default async function content() {
  if (env.context === "web") {
   const data = await fetchApp();
   return data;
  }
  return appContent;
}
