import React from "react";
import { Route, Switch } from "react-router-dom";
import lazy from "react-lazy-with-preload";
import { StyleSheet } from "./styles";
import content from "../../../services/content";
import { useApp } from "../../state/app";

const Home = lazy(() => import("../../pages/home/home"));
const ClinicalAreas = lazy(
  () => import("../../pages/cliinical-areas/clinical-areas")
);
const Studies = lazy(() => import("../../pages/studies/studies"));


Promise.all([
  Home.preload(),
  ClinicalAreas.preload(),
  Studies.preload(),
]).finally(() => {
  console.info("All pages preloaded");
});

export default function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    content().then((appContent) => {
      useApp.setState({ content: appContent });
      setLoading(false);
    });
  }, []);

  if (loading) return <div>Loading.... </div>; //todo: add a loading UI
  return (
    <>
      <StyleSheet />
      <React.Suspense fallback="">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/clinical-areas" component={ClinicalAreas} />
          <Route exact path="/clinical-areas/:slug" component={Studies} />
        </Switch>
      </React.Suspense>
    </>
  );
}
